<template>
	<div class="container">
		<div class="map">
			<img v-if="canGoback" @click="toBackPre" class="toback" src="@/assets/imgs/toBack.png" alt="" />
			<el-cascader v-show="false" :style="{ left: addressInfo.name !== '全国' ? '50px' : '15px' }"
				v-model="checkAddress" placeholder="请选择地区" :props="optionProps" clearable @change="handleChange"
				ref="cascader"></el-cascader>
			<component :mapType="mapType" :height="height" @toShowPlan="toShowPlan" ref="map" :is="currenIndex" v-cloak />
			<div class="listCardWrapper" v-if="showVisitPlan">
				<div v-if="showVisitPlan && (this.typeIndex == 3||mapindex != 1)" class="listCard">
					<div class="card" v-for="i in listCardData" @click="cardClick" :key="i.regionName">
						<div class="title">{{ i.regionName }}</div>
						<div class="line mb12 mt12"></div>
						<div class="content">
							<div class="content-item">
								<countTo class="value" :startVal="0" :endVal="random(500, 2000)"
									:duration="CountAnimationDuration"></countTo>
								<div class="name">重点人群</div>
							</div>
							<div class="content-item">
								<countTo class="value" :startVal="0" :endVal="random(500, 2000)"
									:duration="CountAnimationDuration"></countTo>
								<div class="name">慢性病管理</div>
							</div>
							<div class="content-item">
								<countTo class="value" :startVal="0" :endVal="random(500, 6000)"
									:duration="CountAnimationDuration"></countTo>
								<div class="name">健康档案</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="showVisitPlan && this.typeIndex == 2" class="listCard">
					<div class="team">
						<p>家庭签约医生团队信息</p>
						<div class="team_group">
							<div :class="teamIndex==index?'team_each_on':'team_each'" style="cursor: pointer;" v-for="(item, index) in teaminformation" @click="choseTeam(index)"> {{
								item.teamName }}</div>
						</div>
					</div>
					<div class="team">
						<p>团队成员</p>
						<div class="team_group">
							<div class="team_each" v-for="(item, index) in teamDoc">
								{{ item.doctorLevel }}
								<span>{{ item.name }}-{{ item.phone }}</span>
							</div>
						</div>
					</div>
					<div class="team_list">
						<p>团队成员</p>
						<el-table :data="tableData" height="250" style="width: 100%" :max-height="250" @cell-click="cellClick" :cell-style="drillDown">
							<el-table-column label="序号" width="80" align="center" >
								<template slot-scope="scope">
									<span>{{ (scope.$index + 1) }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="familyName" label="家庭名称" align="center">
							</el-table-column>
							<el-table-column prop="number1" label="成员姓名1" align="center">
								<template slot-scope="scope">
									<span>{{ scope.row.number1 }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="number2" label="成员姓名2" align="center">
							</el-table-column>
							<el-table-column prop="number3" label="成员姓名3" align="center">
							</el-table-column>
							<el-table-column prop="number4" label="成员姓名4" align="center">
							</el-table-column>
							<el-table-column prop="number5" label="成员姓名5" align="center">
							</el-table-column>
						</el-table>
					</div>
					<div class="team_pop_wrapper" v-if="teamPop">
						<div class="team_pop" v-if="teamPop">
							<p class="title">成员详细信息<span @click="teamPop=false"><i class="el-icon-close"></i></span></p>
							<div class="border"></div>
							<div class="main">
								<img src="../../../assets/imgs/people.png" alt="" v-if="gender=='女'">
								<img src="../../../assets/imgs/peopleman.png" alt="" v-else>
								<div class="right">
									<div class="one">{{ teamPopName }}  ( 汉 )  </div>
									<div class="two">
										<p>性 别 ： {{ gender }}</p>
										<p v-if="gender=='女'">身份证号：211382199406265125</p>
										<p v-else>身份证号：210111200011035122</p>
										<p v-if="gender=='女'">出生日期：1994年6月</p>
										<p v-else>出生日期：2000年11月</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="four04" v-if="show404">
				<img src="@/assets/imgs/404.png" />
				<div class="txt">暂无数据</div>
				<div class="btn" @click="toBackPre">返回上一级</div>
			</div>
			<div class="area" v-if="false && mapLevel >= 3 && !showVisitPlan && mapindex != 1">
				<div class="area-head"><span class="triangle" @click="backTown" v-if="mapLevel == 4"></span> 区县乡镇</div>
				<div class="area-content">
					<div class="item" v-for="item in mapDataArr" :key="item.regionName" @click="toLower(item)">{{
						item.regionName }}</div>
				</div>
			</div>
			<div class="button" v-if="false && mapLevel >= 3 && !showVisitPlan && mapindex == 1">
				<div class="areanew" v-if="this.typeIndex == 3">
					<div class="area-head"><span class="triangle" @click="backTown" v-if="mapLevel == 4"></span> 区县乡镇</div>
					<div class="area-content">
						<div class="item" v-for="item in mapDataArr" :key="item.regionName" @click="toLower(item)">{{
							item.regionName }}</div>
					</div>
				</div>
				<div class="areanew" v-if="this.typeIndex == 2">
					<div class="area-head"><span class="triangle" @click="backTown" v-if="mapLevel == 4"></span> 社区列表</div>
					<div class="area-content">
						<div class="item" v-for="item in mapDataArrCom" :key="item.regionName"
							@click="toLowerCommunity(item)">{{
								item.regionName }}</div>
					</div>
				</div>
				<div class="areanew" v-if="this.typeIndex == 1">
					<div class="area-head"><span class="triangle" @click="backTown" v-if="mapLevel == 4"></span> 机构列表</div>
					<div class="area-content">
						<div class="item" v-for="item in institutionData" :key="item.regionName"
							@click="toLowerInstitution(item)" style="cursor: default;">{{institutionCity+item.regionName }}</div>
					</div>
				</div>
				<div class="button_right">
					<p :class="this.typeIndex == 1 ? 'on' : 'group'" @click="choseType(1)">
						<img src="../../../assets/imgs/type1.png" alt="">
						机构
					</p>
					<p :class="this.typeIndex == 2 ? 'on' : 'group'" @click="choseType(2)">
						<img src="../../../assets/imgs/type2.png" alt="">
						社区
					</p>
					<p :class="this.typeIndex == 3 ? 'on' : 'group'" @click="choseType(3)">
						<img src="../../../assets/imgs/type3.png" alt="">
						乡镇
					</p>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import sctterMap from '@/components/sctterMap';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { random } from '@/utils';
import communityData from '@/utils/communityData';

export default {
	props: {
		height: {
			type: Number,
			default: 200,
		},
		mapindex: {
			type: Number,
			default: 0,
		},
    mapType: {
      type: Number
    }
	},
	components: {
		sctterMap,
		countTo,
	},
	data() {
		return {
			show404: false,
			CountAnimationDuration,
			currenIndex: 'sctterMap',
			currentTownInfo: '',
			checkAddress: [],
			searchInfo: {
				searchKey: '',
				regionCode: '',
				type: '',
			},
			value: '',
			parentNode: [],
			showVisitPlan: false,
			optionProps: {
				value: 'code',
				label: 'title',
				children: 'areaList',
				emitPath: true,
				lazy: true,
				checkStrictly: true,
				lazyLoad: (node, resolve) => {
					const { level, value } = node; // 获取当前node对象中的level, value属性
					this.getArea(level, value, resolve);
				},
			},
			listCardData: [],
			typeIndex: 0,
			communityData:communityData,
			institutionData: [
				{ regionName: '某某某某某机构名称1' },
				{ regionName: '某某某某某某某某某某机构名称2' },
				{ regionName: '某某某某某某某某某某机构名称3' },
				{ regionName: '某某某某某机构名称4' },
				{ regionName: '某某某某某机构名称5' },
				{ regionName: '某某某某某机构名称6' },
				{ regionName: '某某某某某机构名称7' },
				{ regionName: '某某某某某某某某某某机构名称8' },
				{ regionName: '某某某某某某某某某某机构名称9' },
				{ regionName: '某机构名称10' },
				{ regionName: '某某某某某机构名称11' },
			],
			teaminformation: [],
			teamDoc: [],
			tableData: [],
			teamIndex:-1,
			institutionCity:'',
			teamPop:false,
			teamPopName:'',
			gender:'女'
		};
	},
	computed: {
    canGoback(){
      return this.parentInfo.length > 1 || (this.$refs && this.$refs.map && this.$refs.map.canGoback())
    },
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo','mapDataArrCom', 'parentInfo']),
	},
	watch: {
		mapDataArr(val) {
			if (this.showVisitPlan) {
				this.listCardData = this.mapDataArr;
			} else {
				this.listCardData = [];
			}
		},
		mapLevel(val) {
			if (val == 3) {
				this.typeIndex = 0
			}
		}
	},
	methods: {
		cardClick() {
			this.show404 = true;
		},
		random,
		...mapMutations('mapData', ['getMapFilter', 'getMapLevel']),
		// 返回镇
		backTown() {
			this.getMapLevel(3);
			this.$store.dispatch('mapData/setAddressInfo', this.currentTownInfo);
			this.$store.dispatch('mapData/setBaseData');
		},
		// 前往下一层
		toLower(item) {
			if (this.mapLevel == 3) {
				this.toShowPlan();
			}
			let data = {
				cityCode: item.regionCode,
				regionCode: item.regionCode,
				name: item.regionName,
			};
			this.$store.dispatch('mapData/setAddressInfo', data);
			this.$store.dispatch('mapData/setBaseData');
			this.getMapLevel(this.mapLevel + 1);
		},
		// 返回上一层
		toBackPre() {
			if (this.show404) {
				this.show404 = false;
				return;
			}
      if(this.showVisitPlan){
        this.showVisitPlan = false;
        this.getMapLevel(3);
        this.$refs.map.toBackPre(false);
        return
      }
			if (this.$refs.cascader.inputValue) {
				let inputValueList = this.$refs.cascader.inputValue.split('/');

				inputValueList.length == 4 ? (inputValueList.length = 3) : '';
				inputValueList.length == 5 ? (inputValueList.length = 4) : '';
				inputValueList.pop();

				this.$refs.cascader.inputValue = inputValueList.join('/');
			}
			this.$refs.map.toBackPre();
		},
		// 搜索
		toSearch() {
			// 关键词搜索
			this.$store.dispatch('mapData/setBaseData');
			this.getMapFilter(this.searchInfo);
		},
		toShowPlan() {
			this.showVisitPlan = true;
		},
		// 处理数字
		formatter(num) {
			if (num < 1000) return num;
			let src = String(num).split('');
			let b = [];
			while (true) {
				num = num / 1000;
				b.push(String(num).indexOf('.'));
				if (num < 1000) {
					break;
				}
			}
			for (var i = 0; i < b.length; i++) {
				src.splice(b[i], 0, ',');
			}
			return (src = src.join(''));
		},
		// 获取地区
		getArea(level, value, resolve) {
			let code = value ? value : null;
			this.$http
				.get(this.api["ChinaRegions#index"].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						const nodes = res.data.collection;
						// level == 3 我这里是四级联动，在最后一级不需要下一级，层级从 0 开始
						if (level == 4) {
							nodes.forEach((item) => {
								// 当点击最后一级的时候 label 后面不会转圈圈 并把相关值赋值到选择器上
								item.leaf = level >= 1;
							});
						}
						resolve(nodes);
					}
				})
				.catch((err) => { });
		},
		// 递归取父节点
		getParentNode(node) {
			this.parentNode.push({
				cityCode: node.value.length <= 6 ? (node.value + '0000').substring(0, 6) : node.value,
				regionCode: node.value,
				name: node.label,
			});
			if (node.parent) {
				this.getParentNode(node.parent);
			}
		},
		handleChange(value) {
			if (value.length === 0) {
				this.checkAddress = [];
			}
			if (this.checkAddress.length > 4) {
				this.$refs['cascader'].dropDownVisible = false;
				this.showVisitPlan = true;
			}
			let checkNodes = this.$refs['cascader'].getCheckedNodes();
			if (checkNodes && checkNodes.length > 0) {
				this.parentNode = [];
				// 保存所有父节点到parentInfo，以便于返回
				checkNodes[0].parent ? this.getParentNode(checkNodes[0].parent) : '';
				let data = {
					cityCode: checkNodes[0].data.code,
					regionCode: checkNodes[0].data.code,
					name: checkNodes[0].data.title,
				};
				this.$refs.map.parentInfo = [
					{
						cityCode: 100000,
						name: '全国',
					},
					...this.parentNode.reverse(),
				];
				if (data.regionCode.length > 4) {
					//直接选择县城，则需要提前获取到上一层地图json
					let code = checkNodes[0].parent.label == '市辖区' ? data.cityCode.substring(0, 2) + '0000' : data.cityCode.substring(0, 4) + '00';
					this.$refs.map.getGeoJson(code, true);
				}
				if (data.regionCode.length <= 6) {
					this.$store.dispatch('mapData/setAddressInfo', data);
					data.cityCode = (data.cityCode + '0000').substring(0, 6);

					let level = 0;
					data.regionCode.length == 2 ? (level = 1) : data.regionCode.length == 4 ? (level = 2) : (level = 3);
					this.getMapLevel(level);

					this.$refs.map.getBaseData(data.cityCode);
					this.$refs.map.parentInfo.push(data);
					this.showVisitPlan = false;
				}
				if (data.regionCode.length == 9) {
					this.$refs.map.getGeoJson(data.cityCode.substring(0, 6));
					this.$refs.map.getBaseData(data.cityCode.substring(0, 6));
					this.$refs.map.parentInfo.push(data);
					this.getMapLevel(4);
					this.$store.dispatch('mapData/setAddressInfo', data);
					this.$store.dispatch('mapData/setBaseData');
					this.currentTownInfo = {
						cityCode: data.cityCode.substring(0, 6),
						regionCode: data.cityCode.substring(0, 6),
						name: checkNodes[0].parent.label,
					};
				} else {
					this.getMapLevel(5);
					this.$store.dispatch('mapData/setAddressInfo', data);
					this.$store.dispatch('mapData/setBaseData');
				}

				this.searchInfo.regionCode = value[4];
				this.getMapFilter(this.searchInfo);
			} else {
				this.$store.dispatch('mapData/setAddressInfo', { cityCode: 100000, name: '全国' });
				this.getMapLevel(0);
				this.$refs.map.getBaseData(100000);
				this.checkAddress = [];
				this.$refs.map.parentInfo = [
					{
						cityCode: 100000,
						name: '全国',
					},
				];
			}
		},
		//切换乡镇、社区、机构
		choseType(val) {
			this.institutionCity=this.mapDataArr[0].name
			this.typeIndex = val
			if(val==2){
			if(this.mapLevel==3){
				let data = {
				cityCode: '',
				regionCode: this.mapDataArr[0].regionCode,
				name: '',
				iscommunity:true
			};
				this.$store.dispatch('mapData/setAddressInfo', data);
				this.$store.dispatch('mapData/setBaseData');
			}
			}
		},
		//前往机构
		toLowerInstitution() {

		},
		//前往社区
		toLowerCommunity(item) {
			if (this.mapLevel == 3) {
				this.toShowPlan();
			}
			let data = {
				cityCode: '',
				regionCode: '',
				name: item.regionName,
			};
			this.$store.dispatch('mapData/setAddressInfo', data);
			// this.$store.dispatch('mapData/setBaseData');
			this.getMapLevel(this.mapLevel + 1);
			this.teaminformation = this.communityData[0].teaminformation

			this.choseTeam(0)
		},
		choseTeam(index) {
			this.teamIndex=index
			this.teamDoc = this.teaminformation[index].teamDoc
			this.tableData = this.teaminformation[index].teamNumber
		},
		cellClick(row, column, cell, event, columnIndex) {
            if (column.label !== '家庭名称'&&column.label !== '序号') {
				this.teamPop=true
				let a=cell.getElementsByClassName('cell')
				this.teamPopName=a[0].innerText
				if(column.label=='成员姓名1'||column.label=='成员姓名4'){
					this.gender='男'
				}else{
					this.gender='女'
				}
            }
        },
		drillDown({ row, column, rowIndex, columnIndex }) {
            if (columnIndex !== 1&&columnIndex !== 0) {
                return {
                    // 'color': '#44AEFF',
                    // 'text-decoration': 'underline',
					'text-decoration': 'underline cyan 2px',
                    'cursor': 'pointer',
                    'text-align': 'center'
                }
            } else {
                return {
                    'text-align': 'center',
                    // 'color': "#666666"
                }
            }
        },
	},
};
</script>

<style lang="scss" scoped>
.listCardWrapper {
	width: calc(100% - 8px);
	height: calc(600px - 4px);
	overflow-y: auto;
	position: absolute;
	left: 4px;
	top: 66px;
	background-color: #041735;
}

.listCard {
	display: flex;
	flex-wrap: wrap;
	// gap: 12px;
	padding: 12px;
	// justify-content: space-around;
	// align-items: start;

	.card {
		height: 116px;
		width: 23%;
		margin: 0 1% 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-image: url('../assets/card_bg.png');
		background-size: 100% 100%;
		cursor: pointer;

		.title {
			color: #71bbff;
			font-size: 16px;
			height: 30px;
			line-height: 30px;
		}

		.line {
			width: 148px;
			height: 1px;
			background: linear-gradient(270deg, rgba(94, 177, 252, 0) 0%, #2e80c9 45%, rgba(92, 177, 253, 0) 100%);
		}

		.content {
			width: 90%;
			display: flex;
			justify-content: space-around;

			.content-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.name {
					font-size: 14px;
					color: #c4e1fb;
				}

				.value {
					font-weight: bold;
					color: #00ebfa;
					font-size: 18px;
				}
			}
		}
	}

	.team {
		width: 100%;
		height: 135px;

		p {
			height: 60px;
			line-height: 60px;
			position: relative;
			padding-left: 15px;

		}

		p::before {
			content: '';
			width: 4px;
			height: 15px;
			background-color: #0BF9FE;
			position: absolute;
			left: 0;
			top: 23px;
		}

		.team_group {
			height: 72px;
			display: flex;
			padding-left: 5px;

			.team_each {
				width: 165px;
				height: 70px;
				background: rgba(36, 65, 110, 0.24);
				border: 2px solid #24416E;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 15px;
				font-size: 16px;

				span {
					font-size: 14px;
					margin-top: 5px;
				}
			}
			.team_each_on{
				width: 165px;
				height: 70px;
				background: rgba(36, 65, 110, 0.24);
				border: 2px solid #24416E;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-right: 15px;
				font-size: 16px;
				border: 2px solid #0BEFFE;
				span {
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
	}

	.team_list {
		width: 100%;
		height: 300px;

		p {
			height: 60px;
			line-height: 60px;
			position: relative;
			padding-left: 15px;

		}

		p::before {
			content: '';
			width: 4px;
			height: 15px;
			background-color: #0BF9FE;
			position: absolute;
			left: 0;
			top: 23px;
		}
	}
	.team_pop_wrapper{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color:rgba(0,0,0,0.5);
	}
	.team_pop{
		width: 489px;
		height: 273px;
		background: url('../../../assets/imgs/modal-bg.jpg') no-repeat;
		background-size: 489px 273px;
		border: 2px solid #24416E;
		box-shadow: 0px 1px 2px 0px rgba(1,1,1,0.12), 0px 0px 24px 0px rgba(58,206,255,0.24);
		position: absolute;
		top: 50%;
		left: 38%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.title{
			width: 100%;
			height: 47px;
			line-height: 47px;
			font-weight: bold;
			background-color: #3aceff31;
			font-size: 22px;
			font-family: PangMenZhengDao;
			text-indent: 1em;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span{
				display: flex;
				width: 28px;
				height: 28px;
				background: #4fd3ff34;
				margin-right: 10px;
				justify-content: center;
				align-items: center;
				text-indent: 0;
				color: #4FD3FF;
				cursor: pointer;

				i{
					font-style: normal;
				}
			}
		}
		.border{
			width: 100%;
			height: 1px;
			background: linear-gradient(90deg, rgba(58,206,255,0), rgba(58,206,255,0.3), rgba(58,206,255,0));
		}
		.main{
			width: 450px;
			height: 148px;
			background: rgba(12,49,94,0.65);
			border-radius: 6px;
			margin-top: 50px;
			display: flex;
			img{
				display: block;
				width: 126px;
				height: 70%;
				position: absolute;
				top: 55px;
				left: 20px;
			}
			.right{
				width: 250px;
				height: 100%;
				position: absolute;
				right: 50px;
				.one{
					font-weight: bold;
					margin-top: 20px;
					margin-bottom: 20px;
					font-size: 18px;
					}
				.two{
					font-size: 16px;
					font-weight: 400;
					margin-top: 5px;
				}
			}
		}
	}
}

.container {
	.map {
		position: relative;

		.toback {
			width: 22px;
			height: 22px;
			top: 30px;
			position: absolute;
			left: 15px;
			z-index: 10;
			cursor: pointer;
		}

		/deep/ .el-cascader {
			position: absolute;
			left: 50px;
			width: 258px;
			top: 20px;
			color: #fff;
			z-index: 10;

			.el-input__inner {
				height: 42px;
				line-height: 42px;
				border-radius: 0;
				border: 2px solid #1e395e;
				background-color: #031d40;
				box-shadow: inset 0 0 10px -1px #189ee5;
				color: #fff;
			}
		}

		/deep/ .el-select {
			position: absolute;
			right: 15px;
			width: 139px;
			top: 20px;
			color: #fff;
			z-index: 10;

			.el-input__inner {
				height: 42px;
				line-height: 42px;
				border-radius: 0;
				border: 2px solid #1e395e;
				background-color: #031d40;
				box-shadow: inset 0 0 10px -1px #189ee5;
				color: #fff;
			}

			.el-select-dropdown {
				z-index: 9999;
			}
		}

		.search {
			z-index: 11;
			position: absolute;
			right: 15px;
			width: 258px;
			top: 20px;
			box-shadow: inset 0 0 10px -1px #189ee5;
			border: 2px solid #1e395e;
			padding: 4px;
			box-sizing: border-box;
			background-color: #031d40;

			/deep/ .el-input {
				color: #fff;
				z-index: 11;

				.el-input__inner {
					border-radius: 0;
					border: none;
					background-color: #031d40;
					color: #fff;
				}

				.el-input-group__append {
					background-color: #031d40;
					border: none;
					font-size: 18px;
					color: #69c0ef;
				}
			}
		}

		.button {
			display: flex;
			display: none; // 隐藏
			width: 45%;
			height: 40%;
			position: absolute;
			right: 0;
			top: 10px;

			.button_right {
				position: absolute;
				right: 15px;
			}

			.group {
				background: url('../../../assets/imgs/littlebg1.png') no-repeat center;
				background-size: 40px 50px;
				width: 40px;
				height: 50px;
				margin-left: 15px;
				margin-bottom: 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				cursor: pointer;

				img {
					width: 50%;
				}

			}

			.on {
				background: url('../../../assets/imgs/littlebg2.png') no-repeat center;
				background-size: 40px 50px;
				width: 40px;
				height: 50px;
				margin-left: 15px;
				margin-bottom: 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				cursor: pointer;
				img {
					width: 50%;
				}

			}
		}

		.area {
			position: absolute;
			bottom: 20px;
			right: 20px;
			background: url('../../../assets/imgs/area.png') no-repeat center;
			background-size: 100% 100%;
			height: 263px;
			width: 216px;
			// padding: 20px 44px;
			font-size: 14px;

			.area-head {
				width: 100%;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.triangle {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 8px 5px 0;
				border-color: transparent #fff transparent transparent;
				position: absolute;
				left: 12px;
				top: 16px;
				transform: translate(0, -50%);
				cursor: pointer;
			}

			.area-content {
				width: 100%;
				height: 225px;
				padding: 11px 7px;
				overflow-y: scroll;
				display: flex;
				flex-wrap: wrap;

				.item {
					padding: 0 10px;
					height: 32px;
					margin: 5px 4px;
					display: flex;
					align-items: center;
					border: 1px solid #0d336a;
					// border-color: none;
					cursor: pointer;
				}

				.item:hover {
					background: linear-gradient(180deg, #3467d0, rgba(50, 105, 213, 0) 50%, #376fe0 100%);
					border: 1px solid;
					height: 32px;
					border-image: linear-gradient(270deg, #4b8dde 0%, #0d1d3e 50%, #4c8edf) 1 1;
				}
			}
		}

		.areanew {
			background: url('../../../assets/imgs/area.png') no-repeat center;
			background-size: 100% 100%;
			height: 363px;
			width: 80%;
			// padding: 20px 44px;
			font-size: 14px;

			.area-head {
				width: 100%;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.triangle {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 8px 5px 0;
				border-color: transparent #fff transparent transparent;
				position: absolute;
				left: 12px;
				top: 16px;
				transform: translate(0, -50%);
				cursor: pointer;
			}

			.area-content {
				width: 100%;
				height: 315px;
				padding: 11px 7px;
				overflow-y: scroll;
				display: flex;
				flex-wrap: wrap;

				.item {
					padding: 0 10px;
					height: 32px;
					margin: 5px 4px;
					display: flex;
					align-items: center;
					border: 1px solid #0d336a;
					// border-color: none;
					cursor: pointer;
				}

				.item:hover {
					background: linear-gradient(180deg, #3467d0, rgba(50, 105, 213, 0) 50%, #376fe0 100%);
					border: 1px solid;
					height: 32px;
					border-image: linear-gradient(270deg, #4b8dde 0%, #0d1d3e 50%, #4c8edf) 1 1;
				}
			}
		}

		.areanew /deep/::-webkit-scrollbar {
			//滚动条宽高，如果不需要显示滚动条可设置宽高为0
			width: 0px !important;
			height: 0px !important;
		}

		.areanew /deep/::-webkit-scrollbar-thumb {
			//滚动条颜色和圆角
			background-color: #dbd9d9;
			border-radius: 3px;
		}
	}

	.tips {
		display: flex;
		border: 2px solid #264471;
		font-size: 18px;
		height: 162px;

		img {
			width: 24px;
			height: 24px;
		}
	}
}

.four04 {
	width: 100%;
	height: 600px;
	position: absolute;
	left: 0;
	top: 66px;
	background-color: #041735;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.txt {
		color: #2A76C1;
	}

	.btn {
		cursor: pointer;
		margin-top: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 110px;
		height: 33px;
		border-radius: 15px;
		border: 1px solid #2A76C1;
		color: #2A76C1;
	}

	img {
		width: 240px;
		height: 240px;
		margin-top: -100px;
	}
}
/deep/ .el-table {
		.el-table__cell {
			background-color: #153b6b;
			color: #fff;
			padding: 5px 0;
			text-align: center;
			font-size: 14px;
		}

		.el-table__empty-block {
			background-color: #153b6b;

			.el-table__empty-text {
				color: #fff;
			}
		}

		.el-table__header {
			width: auto !important;
		}
	}

	/deep/ .el-table td.el-table__cell,
	/deep/ .el-table th.el-table__cell.is-leaf {
		border-color: #031126 !important;
	}

	/deep/ .el-table--border::after,
	/deep/ .el-table--group::after,
	/deep/ .el-table::before {
		background-color: #031126 !important;
	}

	/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
		background-color: #08203e !important;
	}

	/deep/.el-table tr:nth-child(2n-1) {
		td {
			background-color: #0d2e5a !important;
		}
	}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
	width: 4px !important;
	/*滚动条宽度*/
	background-color: #031227 !important;
	/*滚动条背景颜色*/
	height: 394px !important;
	/*滚动条高度*/
}

/*定义滑块 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important;
	/*滚动条的内阴影*/
	border-radius: 10px !important;
	/*滚动条的圆角*/
	background-color: #203a66 !important;
	/*滚动条的背景颜色*/
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper {
	overflow-x: hidden !important;
}
</style>
